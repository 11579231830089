/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum of permissions types
 */
export enum ProjectPermission {
    VIEW_CONFIG = 'view_config',
    MODIFY_CONFIG = 'modify_config',
}